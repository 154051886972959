import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Image, Spinner} from "react-bootstrap";
import Octicon, {X as XIcon} from "@githubprimer/octicons-react";
import {settings} from "../settings";
import {isToday} from "../functions";
import deleteBtn from "../images/delete.png";
import checkBtn from "../images/check.png";

import formatMoney from "../widgets/commaNumbers";
import moment from "moment-timezone";

import "./TaskRow.css";

class TaskRow extends Component {
    /**
     * Renders a row in the Task Manager table using the given watch object.
     * Fetches an image of the car to use as a preview.
     * @param {Object} props
     * @param {Object} props.watch
     * @param {() => void} props.forceUpdate
     */
    constructor(props) {
        super(props);
        this.state = {
            imageLoading: true,
            imageURL: null,
            deleteDialog: false
        };

        const data = this.props.watch;
        this.watch = data["watch_info"];
        this.bid = data["bid"];
        this.imageReq = data["image_request"];
        this.question = data["question"];
        this.translation = data["translation_info"];

        this.deleteClicked = this.deleteClicked.bind(this);
        this.deleteCancelled = this.deleteCancelled.bind(this);
        this.deleteWatch = this.deleteWatch.bind(this);
    }

    componentDidMount() {
        // Get the first or second image depending on how many are available
        // (assume USS auctions have only the thumbnail, other auctions have
        // multiple images and the first one is the auction sheet).
        fetch(
            `${settings.api_server}/search/auction_car_images/${this.watch.vehicle_id}`,
            {credentials: "include"}
        )
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.images.length > 1 ?
                data.images[1]
                :
                data.images[0]
            )
            .then(
                image => this.setState({
                    ...this.state,
                    imageLoading: false,
                    imageURL: image
                }),
                reason => {
                    console.log("Error fetching images for "
                        + this.watch["car_details"] + ": " + reason);
                    this.setState({
                        ...this.state,
                        imageLoading: false
                    });
                }
            );
    }

    // User has clicked "X" to delete
    deleteClicked() {
        this.setState({...this.state, deleteDialog: true});
    }

    // User has clicked "no" on "are you sure?"
    deleteCancelled() {
        this.setState({...this.state, deleteDialog: false});
    }

    deleteWatch() {
        const url = settings.api_server + "/watch/" + this.watch["vehicle_id"];
        fetch(url, {
            method: "DELETE",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                this.props.forceUpdate()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .catch(reason => console.log("Error deleting watch: " + reason));
    }

    // Return the given content wrapped in a link to the car's auction page.
    // The clickable area fills the entire table cell.
    carLink(element) {
        return <Link target="_blank" className="nostyle" rel="noreferrer"
            to={"/auction_car/" + this.watch["vehicle_id"]}
        >
            <div className="innerCellDiv">
                {element}
            </div>
        </Link>;
    }

    getAuctionHouse() {
        return <td>{this.carLink(this.watch["auction_house"] ?? "Unknown")}</td>;
    }

    getAuctionLot() {
        return <td>{this.carLink(this.watch["auction_lot"] ?? "-")}</td>;
    }

    getCarInfo() {
        return <td>{this.carLink(this.watch["car_details"] ?? "Unknown")}</td>;
    }

    // Return one image of the car if available
    getImage() {
        if (this.state.imageLoading)
            return <td>
                {this.carLink(<Spinner animation="border" variant="primary"/>)}
            </td>;
        else if (this.state.imageURL)
            return <td>
                {this.carLink(<Image src={this.state.imageURL}/>)}
            </td>;
        else
            return <td>
                {this.carLink(<Octicon icon={XIcon}/>)}
            </td>;
    }

    // Return the status of an image request
    getImageRequest() {
        if (this.imageReq == null) {
            return <td>{this.carLink("-")}</td>;
        }
        else if (this.imageReq["completed"]) {
            return <td className="auctionOK">{this.carLink("Completed")}</td>;
        }
        else {
            return <td>{this.carLink("Pending")}</td>;
        }
    }

    // Return the status of a bid request
    getBidRequest() {
        if (this.bid === null) {
            return <td>{this.carLink("-")}</td>;
        }
        else if (this.bid["confirmed"]) {
            return <td className="auctionOK">{this.carLink("Confirmed")}</td>;
        }
        else {
            return <td>{this.carLink("Pending")}</td>;
        }
    }

    // Return the status of a translation request
    getTranslationRequest() {
        if (this.translation === null) {
            return <td>{this.carLink("-")}</td>;
        }
        else if (this.translation["translation_completed"]) {
            return <td className="auctionOK">{this.carLink("Done")}</td>;
        }
        else {
            return <td>{this.carLink("Pending")}</td>;
        }
    }

    // Return the status of the questions the user has asked
    getQuestions() {
        // If there is no question or if it is ignore, display a - character
        if (this.question === null || this.question["ignored"]) {
            return <td>{this.carLink("-")}</td>;
        }
        else if (!this.question["replied"]) {
            return <td>{this.carLink("Pending")}</td>;
        }
        else if (this.question["read"]) {
            return <td>{this.carLink("View thread")}</td>;
        }
        else {
            return <td className="auctionOK">{this.carLink("New")}</td>;
        }
    }

    getBidAmount() {
        if (this.bid === null) {
            return <td>{this.carLink("-")}</td>;
        }
        else {
            return <td>{this.carLink(formatMoney(this.bid["amount"]))}</td>;
        }
    }

    // Return whether a car was sold or unsold at auction, or if it was won by
    // the user (which should be highlighted green)
    getSoldUnsold() {
        // only fill this out if user bid on car and car auction is finished
        if (this.bid === null) {
            return <td>{this.carLink("-")}</td>;
        }
        // If the bid is open, show an empty entry
        else if (this.bid["is_open"]) {
            return <td>{this.carLink(null)}</td>;
        }
        // If the car sold and we won the car, display that it sold and that we won it
        else if (this.bid["sold"] === true && this.bid["won"] === true) {
            return <td className="auctionOK">{this.carLink("Sold - Won")}</td>;
        }
        // If the car did not sell, display unsold
        else if (this.bid["sold"] === false) {
            return <td>{this.carLink("Unsold - Lost")}</td>;
        }
        // If the car sold and we did not win it, display that it sold and that we lost
        else if (this.bid["sold"] === true && this.bid["won"] === false){
            return <td>{this.carLink("Sold - Lost")}</td>;
        }
    }

    // Return the highest bid for the car
    getResultPrice() {
        if (this.bid === null) {
            return <td>{this.carLink("-")}</td>;
        }
        else {
            return <td>{this.carLink(formatMoney(this.bid["sold_for"]))}</td>;
        }
    }

    // Return absolute and relative time of the auction
    // Mostly taken from src/avto-components/result_views/result_row.js
    getAuctionTime() {
        // Auction time should be from car if possible so it's the most recent
        const auction_time = this.watch["auction_time"];
        if (auction_time === null) {
            return <td>{this.carLink("-")}</td>;
        }

        const time_to_auction = moment.tz(auction_time, "Asia/Tokyo");

        // if time of day is midnight, assume only day is set
        if (time_to_auction.hour() === 0) {
            // if time of day not set and auction is today, mark it
            if (isToday(time_to_auction)) {
                return <td>{this.carLink(auction_time.substring(0, 10))}</td>;
            }
            // if it's not today, display time to auction as "in x days"
            else {
                const now = moment.tz("Asia/Tokyo");
                now.set({h: 0, m: 0});
                return <td>{this.carLink(<>{time_to_auction.from(now)}<br/>{auction_time.substring(0, 10)}</>)}</td>;
            }
        }
        // if auction is temporarily marked as far in the future, display
        // "not set"
        if (time_to_auction.isAfter(moment().add(7, "days"))) {
            return <td>{this.carLink("Date not set")}</td>;
        }

        // if displaying full auction time and time_to, base color on proximity
        let color = "auctionOK";
        if (time_to_auction.isBefore()) {
            color = "auctionPassed";
        }
        else if (time_to_auction.isBefore(moment().add(1, "hour"))) {
            color = "auctionNear";
        }
        const content = <>{time_to_auction.fromNow()}<br/>{auction_time} JST</>;
        return <td className={color}>{this.carLink(content)}</td>;
    }

    getDeleteButton() {
        return <td style={{padding: "0px"}}><button onClick={this.deleteClicked}
            style={{margin: "0px", border: "0px", padding: "0px", backgroundColor: "rgba(0, 0, 0, 0)"}}>
            <Image src={deleteBtn} style={{height: "2em"}}/>
        </button></td>;
    }

    getCancelButton() {
        return <button onClick={this.deleteCancelled}
            style={{margin: "0px", border: "0px", padding: "0px", backgroundColor: "rgba(0, 0, 0, 0)"}}>
            <Image src={deleteBtn} style={{height: "2em"}}/>
        </button>;
    }

    getConfirmButton() {
        return <td style={{padding: "0px"}}><button onClick={this.deleteWatch}
            style={{margin: "0px", border: "0px", padding: "0px", backgroundColor: "rgba(0, 0, 0, 0)"}}>
            <Image src={checkBtn} style={{height: "2em"}}/>
        </button></td>;
    }

    render() {
        if (this.state.deleteDialog) {
            return <tr id={this.watch["watch_id"]} className="taskRow">
                {this.getAuctionHouse()}
                {this.getAuctionLot()}
                {this.getCarInfo()}
                {this.getImage()}
                <td colSpan="8"><span style={{float: "right"}}>
                    Are you sure?&nbsp;{this.getCancelButton()}
                </span></td>
                {this.getConfirmButton()}
            </tr>;
        }

        return (
            <tr id={this.watch["watch_id"]} className="taskRow">
                {/* Auction */}
                {this.getAuctionHouse()}

                {/* Lot */}
                {this.getAuctionLot()}

                {/* Vehicle */}
                {this.getCarInfo()}

                {/* Image */}
                {this.getImage()}

                {/* Image Request */}
                {this.getImageRequest()}
                
                {/* Translation Request */}
                {this.getTranslationRequest()}

                {/* Bid Request */}
                {this.getBidRequest()}

                {/* Questions */}
                {this.getQuestions()}

                {/* Bid Amount */}
                {this.getBidAmount()}

                {/* Result */}
                {this.getResultPrice()}

                {/* Sold / Unsold */}
                {this.getSoldUnsold()}

                {/* Auction Time */}
                {this.getAuctionTime()}

                {/* Delete */}
                {this.getDeleteButton()}
            </tr>
        );
    }
}

export default TaskRow;
