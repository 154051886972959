import React, { Component } from "react";
import { connect } from "react-redux";

import {
    ListGroup,
    Container, Row, Col
} from "react-bootstrap";

import { settings } from "../settings";
import Commas from "../widgets/commaNumbers";
import commaNumbers from "../widgets/commaNumbers";
import Octicon, {DesktopDownload} from "@githubprimer/octicons-react";


class ShowBalance extends Component {
    render () {
        // return ListGroup.Items
        // only map the line items if they exist
        const lines = this.props.line_items?.map(x => <ListGroup.Item key={x.line_item_id}><b>{x.item_type}</b>: {Commas(x.amount)} </ListGroup.Item>);

        let total = 0;
        this.props.line_items.map(x => total += x.amount);
        return (

            <ListGroup>
                {lines ?? null}
                <ListGroup.Item key="balance"><b>Balance:</b> {Commas(total)}</ListGroup.Item>
            </ListGroup>

        );
    }
}

class ShowNotes extends Component {
    render () {
        // return ListGroup.Items
        // only map the notes if they exist
        const lines = this.props.notes?.map(x => <ListGroup.Item key={x.note_id}><b>{x.note_author} says:</b> {x.text} </ListGroup.Item>);

        return (

            <ListGroup>
                {lines ?? null}
            </ListGroup>

        );
    }
}

class CarDetail extends Component {
    render () {
        // if the purchase was not found, display an error message
        if (this.props.data.success === false)
        {
            return <Container>
                <Row>
                    <Col>
                        {this.props.data.message}
                    </Col>
                </Row>
            </Container>;
        }
        // if the purchase was found, display the details
        else
        {
            return <Container>
                <Row>
                    <Col md={6}>
                        <b>Exchange rate (JPY per 1 USD):</b> {this.props.data.exchange_rate}
                    </Col>
                    <Col md={6}>
                        &nbsp;
                    </Col>
                    <Col md={6}>
                        <h4>Vehicle info</h4>
                        <ul>
                            <li><b>Year:</b> {this.props.data.year}</li>
                            <li><b>Make:</b> {this.props.data.make}</li>
                            <li><b>Model:</b>  {this.props.data.model}</li>
                            <li><b>Chassis Number:</b>  {this.props.data.chassis_num}</li>
                            <li><b>Auction House:</b>  {this.props.data.auction_house}</li>
                        </ul>
                    </Col>

                    <Col md={6}>
                        <h4>Consignee</h4>
                        <ul>
                            <li><b>Shipping consignee:</b>  {this.props.data.consignee_name}</li>
                            <li className="whitespace"><b>Consignee address:</b> <br /> {this.props.data.consignee_address}</li>
                            <li><b>Consignee Phone:</b>  {this.props.data.consignee_phone}</li>
                            <li><b>Consignee email:</b>  {this.props.data.consignee_email}</li>
                            <li><b>Shipping method:</b>  {this.props.data.ship_method}</li>
                            <li><b>Note:</b>  {this.props.data.user_note}</li>

                        </ul>
                    </Col>
                    <Col md={6}>
                        <h4>Payment</h4>
                        <ul>
                            <li><b>Payment method:</b>  {this.props.data.payment_method}</li>
                            <li><b>Account manager:</b>  {this.props.data.acct_manager_name}</li>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <h4>Purchaser information</h4>
                        <ul>
                            <li className="whitespace">
                                <b>Purchaser address:</b><br />
                                {this.props.data.address}<br />
                                {this.props.data.city}<br />
                                {this.props.data.country}<br />
                            </li>
                            <li><b>Purchaser phone number:</b>  {this.props.data.phone_number}</li>
                            <li className="whitespace"><b>Private note:</b> <br /> {this.props.data.purchase_note}</li>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <h4>Notify party</h4>
                        <ul>

                            <li><b>Notify party email:</b>  {this.props.data.ship_notify}</li>
                            <li><b>Logistics name:</b>  {this.props.data.notify_name}</li>
                            <li><b>Logistics address:</b>  {this.props.data.notify_address}</li>
                            <li><b>Logistics phone:</b>  {this.props.data.notify_phone}</li>
                            <li><b>Logistics note:</b>  {this.props.data.notify_note}</li>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <h4>Shipping info</h4>
                        <ul>
                            <li><b>Ship to Country:</b>  {this.props.data.ship_to_country}</li>
                            <li><b>Export port:</b>  {this.props.data.exportPort}</li>
                            <li><b>Date of Purchase:</b> {this.props.data.date_of_purchase}</li>
                            <li><b>Ship to Port:</b>  {this.props.data.ship_port}</li>
                            <li><b>Dimensions:</b> h: {this.props.data.height}m, w: {this.props.data.width}m, l: {this.props.data.length}m</li>
                            <li><b>m<sup>3</sup>  Cost (USD):</b>  {this.props.data.ship_m3_cost}</li>
                            <li><b>Bolster fee (USD):</b>  {this.props.data.bolster_fee}</li>
                            <li><b>Total Shipping cost:</b> {this.props.data.ship_m3_total_cost}</li>
                        </ul>
                    </Col>
                </Row>
            </Container>;
        }
    }
}

class InvoicesCard extends Component {
    render () {
        if (this.props.loading) { return null; }
        // only map the invoices if they exist
        const view = this.props.car_info.invoices?.map(inv => <ListGroup.Item action
            id={inv.invoice_id}
            key={inv.invoice_id}
            href={settings.api_server + "/purchaseDetail/print/pdf_invoice/" + this.props.car_info.purchase_id + "/" + inv.invoice_id}>
            {inv.released_to_client ? "Due " + inv.due_on_date : "Not released to client:"}, Invoice {inv.invoice_id}, outstanding: {commaNumbers(inv.amount)} JPY
            &nbsp;<Octicon icon={DesktopDownload}/>
        </ListGroup.Item>
        );
        return <ListGroup>
            {view ?? null}
        </ListGroup>;
    }
}

const invoiceMapStateToProps = state => {
    return {
        loading: state.car_detail.loading,
        car_info: state.car_detail.car_info,
        isAdmin: state.profile.is_admin
    };
};


const Invoices = connect(invoiceMapStateToProps)(InvoicesCard);

export { CarDetail, ShowNotes, ShowBalance, Invoices };
