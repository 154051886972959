import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
    Container,
    Modal,
    Pagination,
    Table,
    Form,
    Button
} from "react-bootstrap";

import { getAuctionCarData } from "../actions/auction_car_actions";
import AuctionCarImages from "../auction_car_views/AuctionCarImages";

import { showTranslation, setAllTranslationData, unshowTranslation } from "../actions/translation_actions";
import TranslationModal from "../admin_views/translation_modal/translation_modal";
import { TranslationData } from "../auction_car_views/Tranlsation";

import { settings } from "../settings";
import Octicon, { TriangleDown, TriangleUp } from "@githubprimer/octicons-react";

import LastRefresh from "../widgets/LastRefresh";

import { prettyDateTime } from "../functions";

function compare (a, b) {
    let result = 0;
    if (typeof a === "string") {
        result = a.localeCompare(b);
    } else {
        result = a - b;
    }
    return result;
}

/*
 * Get the minimum data element from a list
 */

function min (data, key) {
    // clone the list
    const new_array = data.slice(0);
    // sort the list
    new_array.sort((a, b) => compare(a[key], b[key]));
    return new_array[0][key];
}

class TranslationRow extends Component {
    constructor () {
        super();
        this.click = this.click.bind(this);
    }

    click () {
    // hit the callback
        this.props.clickHandler(this.props.data.translation_id);
    }

    render () {
    // check how it should show the users.
    // true for country, false for user
        let users = this.props.data.users;

        const style = { cursor: "pointer" };

        // pre-process. Turn nulls into "unknown"
        for (let i = 0; i < users.length; i++) {
            if (users[i].country === null || users[i].country.length === 0) { users[i].country = "unknown"; }
        }

        if (this.props.countryUser) {
            // show country first.
            // Order the users, so the lowest-named country comes first
            users.sort((a, b) => compare(a.country, b.country));
            users = users.map(u => u.country + "(" + u.name + ")");
        } else {
            users.sort((a, b) => compare(a.name, b.name));
            users = users.map(u => u.name + "(" + u.country + ")");
        }

        let status = this.props.data.translation_completed ? "Complete" : "Pending";
        if (this.props.data.lock.locked_by !== null) {
            style.backgroundColor = "#ff8080";
            status = status + " locked by " + this.props.data.lock.locked_by;
        }

        return <tr style={style}>
            <td onClick={this.click}>{this.props.data.description}</td>
            <td onClick={this.click}>{prettyDateTime(this.props.data.auction_date, false, false)}</td>
            <td onClick={this.click}>{status}</td>
            {this.props.isAdmin && <td onClick={this.click}>{users.join(",")}</td>}
        </tr>;
    }
}

/* Scope is handled server-side  - admin gets all, users get theirs

No redux needed. This is really self-contained.
*/
class AllTranslations extends Component {
    constructor () {
        super();
        this.state = {
            loaded: false,
            translations: [],
            selected_id: -1,
            showModal: false,
            page: 0,
            countryUserToggle: true, // true for country, false for user
            pendingOnly: true,

            sortCol: -2, // sort stuff
            sortKey: "auction_date",

            lastUpdate: null,
            fetching: false
        };

        this.items_per_page = 10;
        this.dateClick = this.dateClick.bind(this);
        this.reqByClick = this.reqByClick.bind(this);
        this.statusClick = this.statusClick.bind(this);
        this.carClick = this.carClick.bind(this);
        this.doSort = this.doSort.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.getTransText = this.getTransText.bind(this);
        this.avto_from_id = this.avto_from_id.bind(this);
        this.showTranslation = this.showTranslation.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.toggleCountryUserClick = this.toggleCountryUserClick.bind(this);
        this.togglePendingOnly = this.togglePendingOnly.bind(this);
        this.updateTimes = this.updateTimes.bind(this);
    }

    next () {
        this.setState({ ...this.state, page: this.state.page + 1 });
    }

    prev () {
        this.setState({ ...this.state, page: this.state.page - 1 });
    }

    getTransText (id) {
        for (let i = 0; i < this.state.translations.length; i++) {
            if (this.state.translations[i] === id) { return this.state.translations[i].translation; }
        }
        return "Not found";
    }

    avto_from_id (id) {
        if (!isNaN(id)) {
            for (let i = 0; i < this.state.translations.length; i++) {
                if (this.state.translations[i].translation_id === parseInt(id, 10)) { return this.state.translations[i].vehicle_id; }
            }
        }
        return "0000000";
    }

    showTranslation (transId) {
        if (this.props.isAdmin) {
            // admins see edit window
            this.props.showTranslation(transId, this.avto_from_id(transId));
        } else {
            // normies see a translation
            const newState = { ...this.state };
            newState.showModal = true;
            newState.selected_id = transId;

            getAuctionCarData(this.avto_from_id(transId));
            for (let i = 0; i < this.state.translations.length; i++) {
                if (this.state.translations[i].translation_id === parseInt(transId, 10)) {
                    const data = this.state.translations[i];
                    this.props.setAllTranslationData(data.main, data.description, data.sales_points, data.notes, data.report, data.diagram_notes, data.exporter_notes);
                }
            }
            this.props.showTranslation(transId, this.avto_from_id(transId));

            this.setState(newState);
        }
    }

    handleHide () {
        const newState = this.state;
        newState.showModal = false;
        newState.selected_id = -1;
        this.setState(newState);
        this.props.unshowTranslation();
    }

    componentDidMount () {
        this.fetchData();
        // is this an admin? Show all by default if this is a user
        this.setState({ ...this.state, pendingOnly: this.props.isAdmin });
        this.fetchInterval = setInterval(this.fetchData, 2 * 60 * 1000); // in milliseconds
    }

    componentWillUnmount () {
        clearInterval(this.fetchInterval);
    }

    fetchData () {
        const url = settings.api_server + "/translations/all";
        this.setState({ ...this.state, fetching: true });
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status === 401) { // redirects happen elsewhere
                    return null;
                }
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                let newState = { ...this.state, fetching: false };
                if (data !== null) {
                    newState = {
                        ...newState,
                        translations: this.doSort(data, this.state.sortKey, this.state.sortCol),
                        loaded: true,
                        lastUpdate: new Date()
                    };
                }
                this.setState(newState);
            }.bind(this));
    }

    updateTimes () {
        const url = settings.api_server + "/translations/update";

        this.setState({ ...this.state, fetching: true });
        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function () {
                // ignore the response
                // update the list
                this.fetchData();
            }.bind(this));
    }

    doSort (translations, key, col) {
    // special case for 'requested by' column
        if (Math.abs(col) === 4) {
            key = "name"; // choose how we're looking at translations.
            if (this.state.countryUserToggle) { key = "country"; }
            if (col === -4) {
                translations.sort((a, b) => compare(min(a.users, key), min(b.users, key)));
            } else {
                translations.sort((b, a) => compare(min(a.users, key), min(b.users, key)));
            }
        } else {
            if (col < 0) {
                translations.sort((a, b) => compare(a[key], b[key]));
            } else {
                translations.sort((b, a) => compare(a[key], b[key]));
            }
        }
        return translations;
    }

    carClick () { this.handleSort("description", 1); }
    dateClick () { this.handleSort("auction_date", 2); }
    statusClick () { this.handleSort("translation_completed", 3); }
    reqByClick () { this.handleSort("users", 4); }

    handleSort (key, col) {
    // if it's the same number, reverse it.
        let colUpdate = col;
        if (this.state.sortCol === col) {
            colUpdate *= -1;
        } else {
            // new number, accept it
            colUpdate = col;
        }
        // to the sort, get the new list
        let translations = [...this.state.translations]; // shallow copy
        translations = this.doSort(translations, key, colUpdate);
        // save it all
        this.setState({ ...this.state, translations, sortCol: colUpdate, sortKey: key });
    }

    toggleCountryUserClick () {
        this.setState({ ...this.state, countryUserToggle: !this.state.countryUserToggle });
    }

    togglePendingOnly () {
    // if we're showing everything, sort it.
    // if not, just toggle

        let translations = [...this.state.translations]; // shallow copy
        if (this.state.pendingOnly) {
            // also sort newest to oldest
            // which we have to do...
            translations = this.doSort(translations, "auction_date", 2);
            this.setState({
                ...this.state,
                pendingOnly: !this.state.pendingOnly,
                translations,
                sortKey: "auction_date",
                sortCol: 2
            });
        } else {
            // Show newest first
            translations = this.doSort(translations, "auction_date", -2);
            this.setState({
                ...this.state,
                pendingOnly: !this.state.pendingOnly,
                translations,
                sortKey: "auction_date",
                sortCol: -2
            });
        }
    }

    render () {
    //  keep if it's not completed, or if we don't fare
    // NOTE: this does a LOT of re-work. Could/should be factored out to a higher component.
    // Pass down just the rows to deal with
        const tableDataToShow = this.state.translations.filter(r => !r.translation_completed || !this.state.pendingOnly);
        const tableData = [];

        if (this.state.lastUpdate === null) {
            let rows = 3;
            if (this.props.isAdmin) {
                rows = 4;
            }
            tableData.push(<tr key={"placeholder"}><td colSpan={rows}>Loading...</td></tr>);
        } else {
            for (let i = 0; i < tableDataToShow.length; i++) {
                if (i >= this.items_per_page * (this.state.page) && i < this.items_per_page * (this.state.page + 1)) {
                    tableData.push(<TranslationRow key={tableDataToShow[i].translation_id}
                        clickHandler={this.showTranslation}
                        data={tableDataToShow[i]}
                        countryUser={this.state.countryUserToggle}
                        isAdmin={this.props.isAdmin}
                    />);
                }
            }
        }

        /*
        Return the 'who' only if they are admins
        */
        return (
            <Container>
                {this.props.isAdmin &&
                    <div>
                        <h1 className="whiteTitle">
                            All Translation Requests
                            &nbsp;
                            <Button disabled={this.state.fetching} onClick={this.fetchData}>Refresh list</Button>
                            &nbsp;
                            <Button disabled={this.state.fetching} onClick={this.updateTimes}>Refresh auction times</Button>
                        </h1>
                        <p>Last update: <LastRefresh epoch={this.state.lastUpdate}></LastRefresh></p>
                        <Form.Check
                            type="switch"
                            label="Show requests by country/user"
                            id="toggleCountryUser"
                            onChange={this.toggleCountryUserClick}
                            checked={this.state.countryUserToggle}
                        />
                        <Form.Check
                            type="switch"
                            label="Show pending only"
                            id="togglePending"
                            onChange={this.togglePendingOnly}
                            checked={this.state.pendingOnly}
                        />
                    </div>
                }
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th onClick={this.carClick}>Car {this.state.sortCol === 1 && <Octicon icon={TriangleDown} />} {this.state.sortCol === -1 && <Octicon icon={TriangleUp} />} {Math.abs(this.state.sortCol) !== 1 && "↕"} </th>
                            <th onClick={this.dateClick}>Auction Date {this.state.sortCol === 2 && <Octicon icon={TriangleDown} />} {this.state.sortCol === -2 && <Octicon icon={TriangleUp} />} {Math.abs(this.state.sortCol) !== 2 && "↕"} </th>
                            <th onClick={this.statusClick}>Status {this.state.sortCol === 3 && <Octicon icon={TriangleDown} />} {this.state.sortCol === -3 && <Octicon icon={TriangleUp} />} {Math.abs(this.state.sortCol) !== 3 && "↕"} </th>
                            {this.props.isAdmin && <th onClick={this.reqByClick}>Requested by {this.state.sortCol === 4 && <Octicon icon={TriangleDown} />} {this.state.sortCol === -4 && <Octicon icon={TriangleUp} />} {Math.abs(this.state.sortCol) !== 4 && "↕"} </th>}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Prev onClick={this.prev} disabled={this.state.page === 0}>
                        &larr; Previous Page
                    </Pagination.Prev>
                    <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>Showing results {(this.items_per_page * this.state.page) + 1} to {Math.min(tableDataToShow.length, this.items_per_page * (this.state.page + 1))} of {tableDataToShow.length} </span>
                    <Pagination.Next onClick={this.next} disabled={(this.state.page + 1) * this.items_per_page >= tableDataToShow.length}>
                        Next Page &rarr;
                    </Pagination.Next>
                </Pagination>

                <TranslationModal updateCallback={this.fetchData} />

                <Modal
                    show={this.state.showModal && !this.props.isAdmin}
                    onHide={this.handleHide}
                    size="lg"
                >

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">
                            Translation details:

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><Link to={"/auction_car/" + this.avto_from_id(this.state.selected_id)}>Click here to go to auction page</Link></p>
                        {this.state.showModal && // hide this if not loaded, so it doesn't try to render.
                            <TranslationData
                                main={this.props.main}
                                notes={this.props.notes}
                                sales_points={this.props.sales_points}
                                report={this.props.report}
                                diagram_notes={this.props.diagram_notes}
                                exporter_notes={this.props.exporter_notes}
                            />
                        }
                        <AuctionCarImages />
                    </Modal.Body>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        main: state.translations.main,
        report: state.translations.report,
        notes: state.translations.notes,
        sales_points: state.translations.sales_points,
        diagram_notes: state.translations.diagram_notes,
        exporter_notes: state.translations.exporter_notes
    };
};

const mapDispatchToProps = dispatch => ({
    showTranslation: (id, avto_link) => dispatch(showTranslation(id, null, avto_link)),
    setAllTranslationData: (main, desc, sales, notes, report, diagram, exporter_notes) => dispatch(setAllTranslationData(main, desc, sales, notes, report, diagram, exporter_notes)),
    unshowTranslation: () => dispatch(unshowTranslation())
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTranslations);
