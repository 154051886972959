import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    Container, Row, Col,
    Alert
} from "react-bootstrap";

import ProbablyAuctionSheet from "../widgets/ProbablyAuctionSheet";

import ImageGallery from "react-image-gallery";

import { settings } from "../settings";

import { fetchImages } from "../actions/image_request_list_actions";

class AuctionCarImages extends Component {
    constructor () {
        super();
        this.state = { isBig: [] };
        this.callback = this.callback.bind(this);
    }

    callback (id) {
        /*
        Toggle the size of this image
        */
        const bigList = this.state.isBig;
        bigList[id] = !bigList[id];
        this.setState({ isBig: bigList });
    }

    // vehicle_id may be null if we're still fetching the auction data, so we'll
    // try to fetch the requested images on the initial render and when the car
    // data changes.
    componentDidMount() {
        if (this.props.vehicle_id) {
            fetchImages(this.props.vehicle_id);
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.vehicle_id
            && (!prevProps.vehicle_id
                || prevProps.vehicle_id != this.props.vehicle_id))
        {
            fetchImages(this.props.vehicle_id);
        }
    }

    render () {
        // Don't render if we're still loading
        if (this.props.loading) { return <p>Loading...</p>; }

        // Note: we'll still render if the auction data failed to load, because
        // we may still have requested images to display

        // chop up the image links, which are thumbnails by default
        // inexplicably # delimited
        let image_list = [];
        // `data` might not exist if the auction data wasn't found
        if (this.props.data?.images) {
            image_list = this.props.data.images.split("#");
        }

        function makeGalleryInfo (url) {
            // avto-specific
            // var firstPart = url.split("?")[0];
            // return { original: firstPart, thumbnail: url };
            return { original: url, thumbnail: url };
        }

        function addRequestedImages (uuid) {
            const url = settings.api_server + "/image_requests/images/" + uuid;
            return { original: url, thumbnail: url };
        }

        function normalURL (url) {
            return { original: url, thumbnail: url };
        }

        // divide requested images into auction sheets and non-auction sheets
        const sheets = this.props.requestedImageList.filter(x => x.is_auction_sheet);
        const requested = this.props.requestedImageList.filter(x => !x.is_auction_sheet);

        // Put all images in a list.
        // We need to find which one is the auction sheet and put it first.
        const allImages = [];
        // if there are requested images explicitly marked as auction sheets,
        // put them first
        if (sheets.length > 0) {
            sheets.map(x => allImages.push(addRequestedImages(x.link)));
            requested.map(x => allImages.push(addRequestedImages(x.link)));
            this.props.quickImageList.map(x => allImages.push(normalURL(x)));
            image_list.map(x => allImages.push(makeGalleryInfo(x)));
        }
        // otherwise, put the images listed in the car data first, because
        // non-USS auctions have an auction sheet as the first image
        else {
            image_list.map(x => allImages.push(makeGalleryInfo(x)));
            requested.map(x => allImages.push(addRequestedImages(x.link)));
            this.props.quickImageList.map(x => allImages.push(normalURL(x)));
        }

        // remove duplicates
        const uniqueImages = [];
        // allImages = [...new Set(allImages)];
        // doesn't work because they are simple objects, and it doesn't check the contents.
        let found = false;
        for (let i = 0; i < allImages.length; i++) {
            found = false;
            for (let j = 0; j < uniqueImages.length && !found; j++) {
                if (allImages[i].original === uniqueImages[j].original) {
                    found = true;
                }
            }
            if (!found) {
                uniqueImages.push(allImages[i]);
            }
        }

        // remove the auction sheet from the rest of the images to display it
        // separately (unless the separateSheet prop is false)
        // priority:
        // 1: provided sheets
        // 2: requested images
        // 3: whatver was provided, they provide the sheet first
        const imageGalleryArray = this.props.separateSheet ?
            uniqueImages.slice(1) : uniqueImages;
        let suggestion = null;
        if (uniqueImages.length > 0) {
            suggestion = uniqueImages[0].original;
        }
        let gallery = <ImageGallery
            additionalClass="reactGallery"
            items={imageGalleryArray}
            thumbnailPosition="top"
            showFullscreenButton={true}
            useBrowserFullscreen={false}
            showPlayButton={false}
            showNav={false}
            disableThumbnailScroll={false}
            onMouseOver={null}
            useWindowKeyDown={false /* only listen for arrow keys if focused */}
        />;
        if (imageGalleryArray.length === 0) {
            gallery = null;
        }

        const showCopyright = !this.props.suppressCopyright
            && this.props.requestedImageList.length > 0;

        // shows alert only if they have visible requested images.
        return (
            <Container fluid>
                {showCopyright && <Alert variant="success">Copyright Notice - USS Images only viewable to user {this.props.profile.firstname} {this.props.profile.lastname}. Not to be made public.<br />著作権表示：USSの画像は{this.props.profile.firstname} {this.props.profile.lastname}のみ閲覧可能。公示不可。</Alert>}
                <Row>
                    {this.props.separateSheet && <Col sm={12} md={this.props.size}>
                        <ProbablyAuctionSheet suggestion={suggestion} />
                    </Col>}
                    <Col sm={12} md={this.props.size}>
                        {gallery}
                    </Col>
                </Row>
            </Container>
        );
    }
}

AuctionCarImages.defaultProps = {
    // if true, show the auction sheet separately from the other images
    separateSheet: true,
    // column sizes for auction sheet and image gallery; 6 will result in them
    // being side-by-side, 12 will result in them being stacked vertically
    size: 6,
    // if true, hide the copyright notice when displaying requested images
    // (should usually be false)
    suppressCopyright: false,
};

const mapStateToProps = state => {
    return {
        // use auction_car.id instead of auction_car.data.vehicle_id, that way
        // we can still get the ID to show requested images if the auction data
        // fails to load
        vehicle_id: state.auction_car.id,
        data: state.auction_car.data,
        loading: state.auction_car.loading,

        // profile, for name
        profile: state.profile,

        // requested images
        requestedImageList: state.image_request_list.current_image_list,
        numRequested: state.image_request_list.num_images,

        // quick images
        quickImageList: state.image_request_list.quickImageList
    };
};

export default connect(mapStateToProps)(AuctionCarImages);
