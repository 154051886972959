
import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";

import { connect } from "react-redux";

import { settings } from "./settings";

import {
    Container,
    Table,
    Button,
    Image,
} from "react-bootstrap";

import { setBidList, sortBidList } from "./actions/profile_actions";
import { openBidModal, openBidPrivateNoteModal } from "./actions/bid_actions";
import BidCloseModal from "./admin_views/BidCloseModal";
import CommaNumber from "./widgets/commaNumbers";
import PrivateNoteUpdateModal from "./admin_views/PrivateNoteUpdateModal";
import Octicon, { TriangleDown, TriangleUp } from "@githubprimer/octicons-react";
// use timezone-aware functions to sort bids by auction time, since auction
// times are in JST
import { isTodayTZ, isBeforeTodayTZ, prettyDateTime } from "./functions";

import LastRefresh from "./widgets/LastRefresh";
import ConfirmButton from "./admin_views/BidQueue/ConfirmButton";

class BidItemComponent extends Component {
    constructor () {
        super();
        this.showBidModal = this.showBidModal.bind(this);
        this.showNoteModal = this.showNoteModal.bind(this);
    }

    showBidModal () {
        this.props.openBidModal(this.props.data.bid_id);
    }

    showNoteModal (e) {
    // stop the event from going to the parent (and opening more stuff)
        e.stopPropagation();
        this.props.openBidPrivateNoteModal(this.props.data.bid_id);
    }

    /**
     * Return a thumbnail for the car. Uses the first image for USS, and the
     * second image for non-USS (the first will be the auction sheet).
     */
    getCarImage() {
        let imgURL = null;
        if (this.props.data.auction_images) {
            const imgList = this.props.data.auction_images.split("#");
            // only one image? it's probably a USS auction with just a thumbnail
            if (imgList.length == 1)
                imgURL = imgList[0];
            // more than one? first image is likely the auction sheet
            else if (imgList.length > 1)
                imgURL = imgList[1];
        }
        // Use `fluid` to make it shrink to fit the column's width (which is
        // determined by the imageColumn class applied to the <th> tag)
        return imgURL ? <Image fluid src={imgURL}/> : null;
    }

    render () {
        const x = this.props.data;
        return <tr id={x.bid_id} onClick={this.showBidModal} key={x.bid_id} style={{ cursor: "pointer" }}>
            {/* Auction time */}
            <td>{prettyDateTime(x.bid_datetime, false, false)}</td>
            {/* Image */}
            <td>{this.getCarImage()}</td>
            {/* Customer, vehicle description */}
            <td>{x.name}<br />{x.description}</td>
            {/* Auction name/number */}
            <td>{x.auction_name}<br />{x.auction_number}</td>
            {/* Bid amount and confirm button */}
            <td>{CommaNumber(x.amount)} <ConfirmButton bid_id={x.bid_id} confirmed={x.confirmed} refresh={this.props.refresh} /></td>
            {/* Edit Note button */}
            <td className="whitespaceLeft"><div >{x.private_note}</div><div className="float-right"><Button onClick={this.showNoteModal} size="sm">Edit note</Button></div> </td>
        </tr>;
    }
}
const mapStateToPropsBid = state => {
    return {
        bidList: state.profile.bidList
    };
};

const mapDispatchToPropsBid = dispatch => ({
    setBidList: (bid_list) => dispatch(setBidList(bid_list)),
    openBidModal: (bid_id) => dispatch(openBidModal(bid_id)),
    openBidPrivateNoteModal: (bid_id) => dispatch(openBidPrivateNoteModal(bid_id))
});
const BidItem = connect(mapStateToPropsBid, mapDispatchToPropsBid)(BidItemComponent);

class AllBids extends Component {
    constructor () {
        super();
        this.fetchBids = this.fetchBids.bind(this);
        this.fetchRefresh = this.fetchRefresh.bind(this);
        this.fetchJustBids = this.fetchJustBids.bind(this);
        this.clickHandler = this.clickHandler.bind(this);

        this.state = { refreshing: false, lastUpdate: null };
        this.fetchInterval = setInterval(this.fetchJustBids, 2 * 60 * 1000); // in milliseconds
    }

    componentDidMount () {
    // load all the question threads
        this.fetchJustBids();
    }

    componentWillUnmount () {
        clearInterval(this.fetchInterval);
    }

    fetchJustBids () {
        this.fetchBids(false);
    }

    fetchRefresh () {
        this.fetchBids(true);
    }

    fetchBids (refresh) {
    /*
        -1 gets current user.
        number gets specific user (admin-only)
        */
        const url = settings.api_server + "/bids/open?update=" + refresh;
        this.setState({ ...this.state, refreshing: true });

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setBidList(data);
                this.setState({ ...this.state, refreshing: false, lastUpdate: new Date() });
            }.bind(this));
    }

    clickHandler (e) {
    // show this question
        this.props.setShowThreadId(parseInt(e.target.value, 10));
    }

    render () {
        if (!this.props.loaded) { return <p>Loading...</p>; }

        const toBidItem = bid => <BidItem key={"bid" + bid.bid_id} data={bid}
            refresh={this.fetchJustBids} refreshData={this.fetchRefresh}
        />;
        // split bids into three lists of BidItems (today, earlier, later)
        const today = [], earlier = [], later = [];
        this.props.bidList.forEach(bid => {
            if (isTodayTZ(bid.bid_datetime)) {
                today.push(toBidItem(bid));
            }
            else if (isBeforeTodayTZ(bid.bid_datetime)) {
                earlier.push(toBidItem(bid));
            }
            else {
                later.push(toBidItem(bid));
            }
        });

        // For images, use the same imageColumn class as the auction search
        // results (defined in App.css).

        const tr =
            <tr style={{ cursor: "pointer" }}>
                <th onClick={this.props.sortBidListDate}>Auction Date/Time {this.props.bid_sort_col === "bid_datetime" && !this.props.bid_sort_desc && <Octicon icon={TriangleDown} />} {this.props.bid_sort_col === "bid_datetime" && this.props.bid_sort_desc && <Octicon icon={TriangleUp} />} {this.props.bid_sort_col !== "bid_datetime" && "↕"}</th>
                <th className="imageColumn">Image</th>
                <th onClick={this.props.sortBidListName}>Customer<br />Description {this.props.bid_sort_col === "name" && !this.props.bid_sort_desc && <Octicon icon={TriangleDown} />} {this.props.bid_sort_col === "name" && this.props.bid_sort_desc && <Octicon icon={TriangleUp} />} {this.props.bid_sort_col !== "name" && "↕"}</th>
                <th onClick={this.props.sortBidListAuctionName}>Auction Name<br />Auction Number {this.props.bid_sort_col === "auction_name" && !this.props.bid_sort_desc && <Octicon icon={TriangleDown} />} {this.props.bid_sort_col === "auction_name" && this.props.bid_sort_desc && <Octicon icon={TriangleUp} />} {this.props.bid_sort_col !== "auction_name" && "↕"}</th>
                <th onClick={this.props.sortBidListAmount}>Bid {this.props.bid_sort_col === "amount" && !this.props.bid_sort_desc && <Octicon icon={TriangleDown} />} {this.props.bid_sort_col === "amount" && this.props.bid_sort_desc && <Octicon icon={TriangleUp} />} {this.props.bid_sort_col !== "amount" && "↕"}</th>
                <th onClick={this.props.sortBidListPrivateNote}>Admin Note {this.props.bid_sort_col === "private_note" && !this.props.bid_sort_desc && <Octicon icon={TriangleDown} />} {this.props.bid_sort_col === "private_note" && this.props.bid_sort_desc && <Octicon icon={TriangleUp} />} {this.props.bid_sort_col !== "private_note" && "↕"}</th>
            </tr>;

        const bidTable = bidList => {
            if (bidList.length > 0) {
                return <Table striped bordered size="sm" hover>
                    <thead>
                        {tr}
                    </thead>
                    <tbody>
                        {bidList}
                    </tbody>
                </Table>;
            }
            else {
                return <p>
                    No bids <Button
                        onClick={this.fetchJustBids}
                        disabled={this.state.refreshing}
                        size="sm"
                        variant="outline-secondary"
                    >
                        Check again
                    </Button>
                </p>;
            }
        };
        const todayTable = bidTable(today);
        const earlierTable = bidTable(earlier);
        const laterTable = bidTable(later);

        // fluid container makes it full-width (ignores max-width)
        return (
            <div>
                <PCANav isAdmin={this.props.isAdmin} />
                <Container fluid>
                    <h1 className="whiteTitle">
                        All Bids
                        &nbsp;
                        <Button disabled={this.state.refreshing} onClick={this.fetchJustBids}>Refresh list</Button>
                        &nbsp;
                        <Button disabled={this.state.refreshing} onClick={this.fetchRefresh}>Refresh auction times</Button>
                    </h1>
                    <p>Last update: <LastRefresh epoch={this.state.lastUpdate}></LastRefresh></p>
                    <h2>Earlier bids</h2>
                    {earlierTable}
                    <h2>Today's bids</h2>
                    {todayTable}
                    <h2>Future bids</h2>
                    {laterTable}
                    <BidCloseModal updateCB={this.fetchJustBids} />
                    <PrivateNoteUpdateModal updateCB={this.fetchJustBids} />
                </Container>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        bidList: state.profile.bidList,
        loaded: state.profile.bidsLoaded,
        isAdmin: state.profile.is_admin,
        bid_sort_desc: state.profile.bid_sort_desc,
        bid_sort_col: state.profile.bid_sort_col

    };
};

const mapDispatchToProps = dispatch => ({
    setBidList: (bid_list) => dispatch(setBidList(bid_list)),
    openBidModal: (bid_id) => dispatch(openBidModal(bid_id)),
    sortBidListDate: () => dispatch(sortBidList("bid_datetime")),
    sortBidListName: () => dispatch(sortBidList("name")),
    sortBidListAuctionName: () => dispatch(sortBidList("auction_name")),
    sortBidListAmount: () => dispatch(sortBidList("amount")),
    sortBidListPrivateNote: () => dispatch(sortBidList("private_note"))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllBids);
