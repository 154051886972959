import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../App.css";
import { connect } from "react-redux";

import * as Datetime from "react-datetime";
import "../../node_modules/react-datetime/css/react-datetime.css";

import {
    ListGroup,
    Card,
    FormControl,
    Button,
    Row, Col,
    Form, Table
} from "react-bootstrap";
import Octicon, { ChevronRight } from "@githubprimer/octicons-react";

import commaNumber from "../widgets/commaNumbers";
import MoneyInput from "../widgets/MoneyInput";

import { settings } from "../settings";

/**
 * @typedef {Object} SubmissionInvoice state data for a single invoice in the
 * speedy payment tool
 * @prop {string} invoice_id
 * @prop {string} purchase_id
 * @prop {number} owing the outstanding amount on the invoice
 * @prop {number} amount the amount of the current payment to allocate to this
 * invoice, user-entered
 * @prop {boolean} close if true, close the invoice as completed after the
 * payment is added (only valid if `amount === owing`)
 */

/**
 * @typedef {Object} SubmissionUser state data for the user we're currently
 * adding a payment for
 * @prop {number} user_id
 * @prop {string} username email
 * @prop {string} userString first and last name
 */

/**
 * @typedef {Object} PaymentSubmission state data for the payment we're
 * submitting
 * @prop {SubmissionUser} user
 * @prop {Date} date
 * @prop {string} payType
 * @prop {number | ""} amount total payment amount, initially an empty string
 * @prop {SubmissionInvoice[]} invoiceList data for each of the current user's
 * invoices, and how much of the payment to allocate to them
 * @prop {string} note note to add to the payment
 * @prop {boolean} topUpRequests if true, top up the user's translation and bid
 * requests when the payment is added
 */

/**
 * @typedef {Object} UserListInfo
 * @prop {number} user_id
 * @prop {string} username
 * @prop {string} firstname
 * @prop {string} lastname
 * @prop {boolean} active
 */

/**
 * @typedef {Object} PaymentToolState
 * @prop {boolean} loading if true, we're submitting a payment
 * @prop {string | null} message error which occurred when adding the payment,
 * if any
 * @prop {string} queryString input for looking up a user
 * @prop {UserListInfo[]} userList
 * @prop {string[]} typeOptions all possible payment types
 * @prop {PaymentSubmission} submission
 */

class PaymentTool extends Component {
    constructor () {
        super();
        /** @type {PaymentToolState} */
        this.defaultState = {
            loading: false,
            message: null,
            queryString: "",
            userList: [],
            typeOptions: [],
            submission: {
                user: null,
                date: new Date(),
                payType: null,
                // will become a number once the user inputs something, but
                // we'll initialize it w/ an empty string so the input is blank
                amount: "",
                invoiceList: [],
                note: "",
                topUpRequests: true
            }
        };
        /** @type {PaymentToolState} */
        this.state = {
            ...this.defaultState
        };
        this.customerField = React.createRef();

        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleSubmissionChange = this.handleSubmissionChange.bind(this);
        this.handlePaymentAmountChange = this.handlePaymentAmountChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.userSearch = this.userSearch.bind(this);
        this.setUser = this.setUser.bind(this);
        this.getPayTypes = this.getPayTypes.bind(this);
        this.sufficientData = this.sufficientData.bind(this);
        this.handleTopUp = this.handleTopUp.bind(this);
        this.handleInvoiceClose = this.handleInvoiceClose.bind(this);
    }

    componentDidMount () {
        this.getPayTypes();
    }

    getPayTypes () {
        const url = settings.api_server + "/config_items/payment_types";

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    console.log(response);
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const options = data.config_value.split(",");
                this.defaultState = ({ ...this.defaultState, typeOptions: options });
                this.setState({ ...this.state, typeOptions: options });
            }.bind(this));
    }

    handleUserChange (e) {
    // Could be debounced, but is not frequently used.
        if (e.target.value.length > 0) {
            this.setState({ ...this.state, queryString: e.target.value });
            this.userSearch(e.target.value);
        } else {
            // clear the box if there's no data
            this.setState({ ...this.state, userList: [], queryString: e.target.value });
        }
    }

    handleSubmissionChange (e) {
        const name = e.target.name;
        const val = e.target.value;
        this.setState({
            ...this.state,
            submission: {
                ...this.state.submission,
                [name]: val
            }
        });
    }

    handlePaymentAmountChange(amount) {
        this.setState({
            ...this.state,
            submission: {
                ...this.state.submission,
                amount
            }
        });
    }

    handleRadioChange (e) {
        const name = e.target.name;
        const val = e.target.id;
        this.setState({
            ...this.state,
            submission: {
                ...this.state.submission,
                [name]: val
            }
        });
    }

    handleInvoiceAmountChange(invoiceID, amount) {
        /** @type {PaymentToolState} */
        const newState = this.state;
        let toChange = null;
        for (const invoice of newState.submission.invoiceList) {
            if (invoice.invoice_id === invoiceID) {
                toChange = invoice;
            }
        }
        // this function should only be called with invoice IDs from the
        // invoiceList, so we should always find the invoice
        if (toChange === null)
            return;

        toChange.amount = amount;
        // if the amount is no longer the same as the amount owning, un-check
        // the option to close the invoice
        toChange.close = toChange.close && toChange.amount === toChange.owing;
        this.setState(newState);
    }

    handleInvoiceClose(e) {
        const invoiceID = e.target.name;
        /** @type {PaymentToolState} */
        const newState = this.state;
        let toChange = null;
        for (const invoice of newState.submission.invoiceList) {
            if (invoice.invoice_id === invoiceID) {
                toChange = invoice;
            }
        }
        // all checkboxes for closing invoices should be given a `name` with the
        // ID of the invoice they're for
        if (toChange === null)
            return;

        // the checkbox shouldn't be shown at all if the amounts aren't equal,
        // but just in case, we'll enforce the rule here
        toChange.close = e.target.value && toChange.amount === toChange.owing;
        this.setState(newState);
    }

    handleTopUp () {
        this.setState({
            ...this.state,
            submission: {
                ...this.state.submission,
                topUpRequests: !this.state.submission.topUpRequests
            }
        });
    }

    handlePayment () {
        if (this.sufficientData()) {
            this.setState({ ...this.state, loading: true });
            const postData = this.state.submission;
            const url = settings.api_server + "/invoice/admin/payment";

            fetch(url, {
                credentials: "include",
                body: JSON.stringify(postData),
                method: "POST",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(resp => resp.ok ?
                    resp.json()
                    : Promise.reject(resp.status + " " + resp.statusText)
                )
                .then(body => {
                    if (!body.success)
                        return Promise.reject(body.message);
                    // the payment succeeded, reset our state and focus the
                    // customer input for the user to enter another payment
                    this.setState({ ...this.defaultState });
                    this.customerField.current.focus();
                })
                .catch(message => {
                    console.log("Error adding payment: " + message);
                    this.setState({ ...this.state, message, loading: false });
                });
        }
    }

    userSearch (query) {
        const url = settings.api_server + "/user/getUsers/" + query;

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, userList: data });
            }.bind(this));
    }

    sufficientData () {
        let result = true;
        /** @type {PaymentSubmission} */
        const submission = this.state.submission;
        let message = "";
        let totalPaid = 0;
        if (submission.user === null) {
            result = false;
            message += "User cannot be empty.\n";
        }
        if (submission.payType === null) {
            result = false;
            message += "Payment Type cannot be empty.\n";
        }
        // if the user hasn't entered an amount (empty string), or entered a
        // number <= 0, don't attempt to add the payment
        if (!submission.amount || submission.amount <= 0) {
            result = false;
            message += "Amount paid must be greater than zero.\n";
        }

        submission.invoiceList.forEach(invoice => {
            totalPaid += invoice.amount;
            if (invoice.amount > invoice.owing) {
                result = false;
                message += "Amount paid on invoice " + invoice.invoice_id + " must not be more than what is owing.\n";
            }
            if (invoice.amount < 0) {
                result = false;
                message += "Amount paid on invoice " + invoice.invoice_id + " must be greater than zero.\n";
            }
        }
        );
        if (totalPaid > submission.amount) {
            message += "Invoice payments sum exceeds amount received.\n";
        }
        if (!result) {
            this.setState({ ...this.state, message });
        }
        return result;
    }

    setUser (user) {
        this.setState({
            ...this.state,
            queryString: "",
            userList: [],
            submission: {
                ...this.state.submission,
                user: {
                    user_id: user.user_id,
                    username: user.username,
                    userString: user.firstname + " " + user.lastname
                }
            }
        });

        const url = settings.api_server + "/invoice/admin/single/" + user.user_id;

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                /** @type {PaymentToolState} */
                const newState = this.state;
                for (let i = 0; i < data.length; i++) {
                    data[i].amount = 0;
                    data[i].close = false;
                }
                newState.submission.invoiceList = data;
                this.setState(newState);
            }.bind(this));
    }

    render () {
        const userData = this.state.userList.map(x =>
            <ListGroup.Item
                onClick={() => this.setUser(x)}
                action id={x.user_id}
                key={x.user_id}
            >
                <Row>
                    <Col md={6}>{x.username}</Col>
                    <Col md={6}>{x.firstname} {x.lastname}</Col>
                </Row>
            </ListGroup.Item>
        );

        // list the payment types
        // state value this.state.submission.payType is set
        // check off values appropriately
        const paymentType = this.state.typeOptions.map(x =>
            <Form.Check
                key={x}
                onChange={this.handleRadioChange}
                checked={x === this.state.submission.payType}
                type='radio'
                label={x}
                name='payType'
                id={x}
            />
        );

        /** @type {PaymentSubmission} */
        const submission = this.state.submission;

        // Any money from the payment not allocated to invoices will go towards
        // the user's deposit, so calculate that amount to display it.
        let depositTotal = submission.amount;
        for (let i = 0; i < submission.invoiceList.length; i++) {
            depositTotal -= submission.invoiceList[i].amount;
        }

        // If the amount entered equals the amount owing for any of the invoices
        // we'll show an extra column with toggles for closing them.
        let showCloseInvoice = false;
        for (const invoice of submission.invoiceList) {
            if (invoice.amount === invoice.owing) {
                showCloseInvoice = true;
                break;
            }
        }

        const invoices = submission.invoiceList.map(x =>
            <tr key={x.invoice_id}>
                <td>
                    <Link to={"/purchase_detail/" + x.purchase_id}
                        target="_blank" rel="noopener noreferrer"
                    >
                        {x.invoice_id}
                    </Link>
                </td>
                <td>
                    ¥{commaNumber(x.owing)} <Button
                        size="sm"
                        onClick={() => this.handleInvoiceAmountChange(
                            x.invoice_id, x.owing
                        )}
                        style={{padding: "2px 4px"}}
                    >
                        Copy <Octicon icon={ChevronRight}/>
                    </Button>
                </td>
                <td>
                    <MoneyInput
                        value={x.amount}
                        onChange={(value) => this.handleInvoiceAmountChange(
                            x.invoice_id, value
                        )}
                    />
                </td>
                {showCloseInvoice && <td>
                    {x.amount === x.owing && <Form.Check
                        id={"close_" + x.invoice_id}
                        name={x.invoice_id}
                        checked={x.close}
                        label="Close"
                        onChange={this.handleInvoiceClose}
                    />}
                </td>}
            </tr>
        );

        return (<Card id="collapsible-Card-example-2" >
            <Card.Header>
                <Card.Title>
                    Speed Payment Tool
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Row>
                        <Col md={5}>
                            Date:
                            {<Datetime closeOnSelect value={submission.date}
                                onChange={(e) => this.setState({
                                    ...this.state,
                                    submission: {
                                        ...submission,
                                        date: e._d
                                    }
                                })}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                            />}
                            Customer:
                            {submission.user != null
                                ? <div>{" " + submission.user.userString + " - " + submission.user.username}</div>
                                : <div>
                                    <FormControl autoComplete="off"
                                        ref={this.customerField}
                                        value={this.state.queryString}
                                        onChange={this.handleUserChange}
                                    />
                                    {userData}
                                </div>
                            }
                            Amount:
                            <MoneyInput
                                value={submission.amount}
                                onChange={this.handlePaymentAmountChange}
                            />
                        </Col>
                        <Col>
                            Payment Type:
                            {paymentType}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            Invoices:
                            <Table>
                                <thead>
                                    <tr style={{textAlign: "center"}}>
                                        <th>Invoice ID</th>
                                        <th>Amount Owing</th>
                                        <th>Amount to be Paid</th>
                                        {showCloseInvoice && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices}
                                    {/* Only show a row with the amount going
                                        towards the deposit if a user has been
                                        selected and their invoices are being
                                        displayed */}
                                    {submission.user && <tr>
                                        <td colSpan={2}>Amount to add to deposit:</td>
                                        <td><MoneyInput value={depositTotal} disabled/></td>
                                        {showCloseInvoice && <td></td>}
                                    </tr>}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            Note:
                            <Form.Control as="textarea" name='note' value={submission.note} onChange={this.handleSubmissionChange} rows={2} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Check label="Top up requests" checked={submission.topUpRequests} onChange={this.handleTopUp} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            {this.state.message !== null && this.state.message.split("\n").map((item, key) => {
                                return (<span key={key}>{item}<br /></span>);
                            })
                            }

                            <Button disabled={this.state.loading} onClick={this.handlePayment} > Save Payment</Button>
                            &nbsp;
                            <Button onClick={() => this.setState({ ...this.defaultState })} variant="outline-danger">Reset</Button>
                        </Col>
                    </Row>
                </Col>
            </Card.Body>
        </Card>);
    }
}

const mapStateToProps = state => {
    state;
    return {
    };
};


export default connect(mapStateToProps)(PaymentTool);
