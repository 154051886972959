
const image_request_list_defaultState = {
    showModal: false,
    showModalWhich: null,
    request_list: [],
    request_list_loaded: false,
    current_image_list: [],
    // these two are used to ensure we ignore duplicate requests when already
    // fetching for a given car, and cancel old requests when fetching for a
    // different car.
    current_images_fetching: false,
    current_images_vehicle_id: "",

    num_images: 0,
    notes: "",
    quickImagesLoaded: false,
    quickImageList: [],
    quickImageRequested: false
};

const alert = (state = image_request_list_defaultState, action) => {
    switch (action.type) {
    // for showing the admin request queue
    case "SET_REQUEST_LIST":
        return {
            ...state,
            request_list: action.request_list,
            request_list_loaded: true

        };
    case "CLEAR_REQUEST_LIST":
        return {
            ...state,
            request_list: action.request_list,
            request_list_loaded: false
        };
    case "SHOW_REQUEST_LIST_MODAL":
        return {
            ...state,
            showModal: true,
            showModalWhich: action.showRequest
        };
    case "CLEAR_REQUEST_LIST_MODAL":
        return {
            ...state,
            showModal: false,
            showModalWhich: image_request_list_defaultState.showModalWhich,
            num_images: 0,
            current_image_list: []
        };

        // quick image list
    case "SET_QUICK_IMAGE_LIST":
        return {
            ...state,
            quickImageList: action.image_list,
            quickImagesLoaded: true,
            quickImageRequested: action.wasRequested,
            num_images: state.current_image_list.length + action.image_list.length // for refreshing the data - changes to the list are not detected
        };

        // handling images for admin views, and for user views
    case "ADD_REQUESTED_IMAGE":
        state.current_image_list.push(action.url);
        return {
            ...state,
            num_images: state.current_image_list.length + state.quickImageList.length // for refreshing the data - changes to the list are not detected
        };
    case "ADD_REQUESTED_IMAGES":
        var all = action.urls.concat(state.current_image_list);
        return {
            ...state,
            current_image_list: all,
            num_images: state.current_image_list.length + state.quickImageList.length // for refreshing the data - changes to the list are not detected
        };
    case "ADD_IMAGE_AND_STATUS":
        var allurls = action.urls.concat(state.current_image_list);
        return {
            ...state,
            current_image_list: allurls,
            hasRequestedImages: action.hasRequestedImages,
            notes: action.notes,

            num_images: state.current_image_list.length + state.quickImageList.length // for refreshing the data - changes to the list are not detected
        };

    case "CLEAR_REQUEST_LIST_IMAGES":
        return {
            ...state,
            num_images: 0,
            current_image_list: [],
            // cancel any pending request
            current_images_fetching: false,
            current_images_vehicle_id: "",
            quickImagesLoaded: false,
            quickImageList: [],
            quickImageRequested: false
        };

    case "REMOVE_REQUESTED_IMAGE":
        state.current_image_list = state.current_image_list.filter(x => x.link !== action.uuid);
        return {
            ...state,
            num_images: state.current_image_list.length + state.quickImageList.length
        };

    // assumes the caller will guard against duplicate fetches
    case "FETCHING_IMAGE_LIST":
        return {
            ...state,
            current_images_fetching: true,
            current_images_vehicle_id: action.vehicle_id,
        };

    // assumes the caller won't store fetches that should've been cancelled
    case "IMAGE_LIST_FETCHED":
        return {
            ...state,
            num_images: state.quickImageList.length + action.image_list.length,
            current_image_list: action.image_list,
            current_images_fetching: false
        };

    default:
        return state;
    }
};

export default alert;
