import React, { Component } from "react";


import { settings } from "../../settings";

import {
    Alert,
    Button,
    Container,
    FormControl,
    ListGroup
} from "react-bootstrap";


export default class ListOfQuestions extends Component {

    constructor() {
        super();
        this.loadData = this.loadData.bind(this);
        this.save = this.save.bind(this);
        this.changeResponse = this.changeResponse.bind(this);
        this.state = { pushing_data: false, errorMessage: null, response: "", convo: [], loaded: false };
    }

    componentDidMount() {
        // grab the data
        this.loadData();
    }

    loadData() {

        const url = settings.api_server + "/questions/user/" + this.props.vehicle_id + "/" + this.props.user_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, convo: data, loaded: true });
            }.bind(this));
    }

    changeResponse(e) {
        this.setState({ ...this.state, response: e.target.value });
    }

    save() {
        this.setState({ ...this.state, pushing_data: true });

        const postdata = {
            text: this.state.response
        };

        const url = settings.api_server + "/questions/user/" + this.props.vehicle_id + "/" + this.props.user_id;
        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Problem contacting server" });
                    throw new Error("Bad response from server");
                } else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.setState({ pushing_data: false, errorMessage: null, response: "" });
                    this.loadData();
                } else {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Could not save translation" });
                }
            }.bind(this));
    }



    render() {

        if (!this.state.loaded) {
            return "Loading...";
        }

        let convo = <ListGroup.Item className="whitespace">No questions</ListGroup.Item>;
        if (this.state.convo.length > 0) {
            convo = this.state.convo.map((t) => <ListGroup.Item className="whitespace" key={t.text + t.author_name}><b>{t.author_name} says:</b> {t.text}</ListGroup.Item>);
        }


        return <Container>
            <ListGroup>{convo}</ListGroup>
            <FormControl rows="5" as="textarea" onChange={this.changeResponse} value={this.state.response} placeholder="Write your question or reply here" />
            <Button disabled={this.state.pushing_data} onClick={this.save}>Add</Button>
            {
                this.state.errorMessage !== null && <Alert>{this.state.errorMessage}</Alert>
            }
        </Container>;
    }

}