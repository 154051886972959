import {settings} from "../settings";

/**
 * Fetch the requested images for the given vehicle. For admins, it will get all
 * images. For non-admins, it will only get images released to that user.
 * NOTE: will replace the entire current_image_list with the fetched list.
 * Avoids race conditions by tracking whether we're already fetching images, and
 * for which vehicle. This way, only components which actually render the images
 * need to worry about fetching them, and they don't need to worry about
 * fetching images at the same time as another component.
 * This logic is only for regular image requests, not quick images (which are
 * disabled at the moment)
 * @param {string} vehicle_id
 */
const fetchImages = function (vehicle_id) {
    const beforeFetch = settings.store.getState().image_request_list;
    // if we're already fetching for this vehicle, ignore
    if (!beforeFetch.current_images_fetching
        || vehicle_id != beforeFetch.current_images_vehicle_id)
    {
        settings.store.dispatch({
            type: "FETCHING_IMAGE_LIST",
            vehicle_id
        });
        const url = settings.api_server + "/image_requests/vehicle/" + vehicle_id;
        fetch (url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                // a 404 just means there aren't any requested images
                // for the car, so we'll return an empty list
                else if (resp.status == 404) {
                    return Promise.resolve({requestedImageList: []});
                }
                // any other error status is actually an error
                else {
                    return Promise.reject(resp.status, resp.statusText);
                }
            })
            .then(
                data => {
                    const afterFetch = settings.store.getState().image_request_list;
                    // if we switched to fetching images for a different car,
                    // or aborted the fetch by setting current_images_fetching
                    // to false, don't store these images
                    if (afterFetch.current_images_fetching &&
                        afterFetch.current_images_vehicle_id == vehicle_id)
                    {
                        settings.store.dispatch({
                            type: "IMAGE_LIST_FETCHED",
                            image_list: data.requestedImageList
                        });
                    }
                },
                reason => {
                    console.log(`Failed to fetch requested images: ${reason}`);
                }
            );
    }
};

const setRequestList = function (request_list) {
    return {
        type: "SET_REQUEST_LIST",
        request_list

    };
};

const clearRequestList = function () {
    return {
        type: "CLEAR_REQUEST_LIST"
    };
};

const showRequestModal = function (which) {
    return {
        type: "SHOW_REQUEST_LIST_MODAL",
        showRequest: which
    };
};
const hideRequestModal = function () {
    return {
        type: "CLEAR_REQUEST_LIST_MODAL"
    };
};

const addImage = function (url) {
    return {
        type: "ADD_REQUESTED_IMAGE",
        url
    };
};

const userAddImages = function (requestedImageList, isImageRequested, notes) {
    return {
        type: "ADD_IMAGE_AND_STATUS",
        hasRequestedImages: isImageRequested,
        urls: requestedImageList,
        notes
    };
};

const addImages = function (urls) {
    return {
        type: "ADD_REQUESTED_IMAGES",
        urls
    };
};

const removeImage = function (uuid) {
    return {
        type: "REMOVE_REQUESTED_IMAGE",
        uuid
    };
};

// Clears any images we've fetched and cancels in-progress fetches
const clearImages = function () {
    return {
        type: "CLEAR_REQUEST_LIST_IMAGES"
    };
};

const setQuickImageList = function (image_list, wasRequested) {
    return {
        type: "SET_QUICK_IMAGE_LIST",
        image_list,
        wasRequested
    };
};

export {
    fetchImages,
    setRequestList,
    clearRequestList,
    showRequestModal,
    hideRequestModal,
    addImage,
    clearImages,
    addImages,
    removeImage,
    userAddImages,
    setQuickImageList
};
