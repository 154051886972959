
import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";

import { connect } from "react-redux";

import { settings } from "./settings";

import {
    ListGroup, Container,
    Button,
    Form
} from "react-bootstrap";
import {
    setThreadList,
    setFetchingThread,
    setShowThreadId,
    clearThreadList
} from "./actions/questions_actions";
import QuestionModal from "./admin_views/question_answer_modal";
import { prettyDateTime } from "./functions";
import LastRefresh from "./widgets/LastRefresh";

class Questions extends Component {
    constructor() {
        super();
        this.fetchQuestions = this.fetchQuestions.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.toggleIgnored = this.toggleIgnored.bind(this);
        this.toggleReplied = this.toggleReplied.bind(this);
        this.state = { showIgnored: false, showReplied: false, refreshing: false };

        this.fetchInterval = setInterval(this.fetchQuestions, 2 * 60 * 1000); // in milliseconds
    }

    componentDidMount() {
        // load all the question threads
        this.fetchQuestions();
    }

    componentWillUnmount() {
        this.props.clearThreadList();
        // clear the interval
        clearInterval(this.fetchInterval);
    }

    toggleIgnored() {
        this.setState({ ...this.state, showIgnored: !this.state.showIgnored });
    }

    toggleReplied() {
        this.setState({ ...this.state, showReplied: !this.state.showReplied });
    }

    fetchQuestions() {
        const url = settings.api_server + "/questions/thread/all";

        this.setState({ ...this.state, refreshing: true });
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setThreadList(data);
                this.setState({ ...this.state, refreshing: false });
            }.bind(this));
    }

    clickHandler(e) {
        // show this question
        this.props.setShowThreadId(parseInt(e.target.value, 10));
    }

    render() {
        if (!this.props.loaded) { return <p>Loading...</p>; }

        let theList = this.props.question_list;
        if (!this.state.showIgnored)
        // then filter them
        { theList = theList.filter(q => q.meta.ignored !== true); }
        if (!this.state.showReplied)
        // then filter them
        { theList = theList.filter(q => q.meta.replied !== true); }
        const questions = theList.map((q) => <ListGroup.Item action onClick={this.clickHandler} value={q.thread_id} key={q.thread_id} className="preview">
            {q.asker_name}, {prettyDateTime(q.last_post, true, false)}, {q.meta.ignored ? "Ignored" : q.meta.replied ? "Replied" : "Unanswered"} - {q.first_question}
        </ListGroup.Item>);

        return (
            <div>
                <PCANav isAdmin={this.props.isAdmin} />
                <Container className="wideContainer">
                    <h1 className="whiteTitle">
                        All Questions
                        &nbsp;
                        <Button disabled={this.state.refreshing} onClick={this.fetchQuestions}>Refresh</Button>
                    </h1>
                    <p>Last update: <LastRefresh epoch={this.state.lastUpdate}></LastRefresh></p>
                   
                    <Form.Check
                        type="switch"
                        label="Show Ignored Questions"
                        id="showIgnoredQuestions"
                        checked={this.state.showIgnored}
                        onClick={this.toggleIgnored}
                    />
                    <Form.Check
                        type="switch"
                        label="Show Replied Questions"
                        id="showRepliedQuestions"
                        checked={this.state.showReplied}
                        onClick={this.toggleReplied}
                    />

                    <ListGroup>{questions}<QuestionModal update={this.fetchQuestions} /></ListGroup>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loaded: state.questions.question_list_loaded,
        question_list: state.questions.question_list,

        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setThreadList: (the_list) => dispatch(setThreadList(the_list)),
    setFetchingThread: () => dispatch(setFetchingThread()),
    setShowThreadId: (id) => dispatch(setShowThreadId(id)),
    clearThreadList: () => dispatch(clearThreadList())
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
