import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    Alert, Navbar, Container, Row, Col, Nav, Modal, Button, NavDropdown
} from "react-bootstrap";

import { ensureLogin, logout, failedLogin } from "../actions/profile_actions";
import { setMessage, clearMessage, clearPopup } from "../actions/alert_actions";
import { settings } from "../settings";
import { fetch_translations } from "../actions/make_model_translation_actions";
import commaNumbers from "../widgets/commaNumbers";
import TimeInJapan from "./TimeInJapan";

import "./navbar.css";

// Width of each main button in pixels (Home, Auction Search, etc.)
const BUTTON_WIDTH = 100;

class PCANav extends Component {
    constructor() {
        super();
        this.timer = null;

        this.doLogout = this.doLogout.bind(this);
    }

    /*
    Nav is only visible when logged in. Check that the user is logged in...
    */
    componentDidMount() {
        ensureLogin();
        fetch_translations();

        document.title = "Japanese Auction Search";
    }

    componentDidUpdate(oldProps) {
        if (this.props.message !== oldProps.message) {
            if (this.props.message != null) {
                // show a message for a few seconds, and clear
                if (this.timer !== null) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(this.props.clearMessage, 10000);
            }
        }
    }

    doLogout() {
        const url = settings.api_server + "/logout";
        fetch(url, {
            cache: "no-cache",
            headers: {
                pragma: "no-cache",
                "cache-control": "no-store"
            },
            method: "GET",
            credentials: "include"
        }
        )
            .then(function (response) {
                if (response.status !== 301) { console.log("failed logout"); }
            });

        // clear redux
        this.props.logout();
    }

    render() {
        // loading
        if (this.props.fetchingProfile) { return (<p>Loading...</p>); }
        // profile loaded, login failed. Return to login page.
        if (!this.props.fetchingProfile && !this.props.loggedIn) {
            // set the redirect-to-after-login data
            console.log(this.props.location.pathname);
            // return(<Redirect to="/"/>);
            this.props.history.push({
                pathname: "/",
                search: "?redirect=" + this.props.location.pathname
            });

            return null;
        }
        // we can now assume there is a valid profile
        let adminLink = "";
        let numItems = 3; // default: Home, Auction Search and Auction History
        if (this.props.isAdmin) {
            adminLink = <NavDropdown className="LinkButtons Navbar_Buttons AdminDropdown" title="Admin" id="nav-dropdown">
                {(this.props.profile.is_queue_admin || this.props.profile.is_acct_manager) && <NavDropdown.Item href="#/requests" eventKey="4.1">Request Monitoring</NavDropdown.Item>}
                {this.props.profile.is_accounts_admin && <NavDropdown.Item href="#/accounts" eventKey="4.2">Accounts and User Management</NavDropdown.Item>}
                {this.props.profile.is_founder_admin && <NavDropdown.Item href="#/manage" eventKey="4.3">Site Management</NavDropdown.Item>}
                {this.props.profile.is_founder_admin && <NavDropdown.Item href="#/reports" eventKey="4.4">Reports</NavDropdown.Item>}
            </NavDropdown>;
            numItems = 4; // admins get Admin button
        }
        return (
            <div>
                {/* Always expand the contents, if they go vertical it blocks stuff */}
                <Navbar id="MainNav" className="Navbar" expand>

                    <Navbar.Brand href="#/dashboard">
                        <img
                            src={settings.api_server + "/logo.jpg"}
                            width="90"
                            height="90"
                            className="d-inline-block align-top"
                            alt="Auction Search Home"
                            style={{borderRadius: "50%"}}
                        />
                    </Navbar.Brand>
                    <Container className="dropShadow Navbar_Buttons" fluid>
                        {/* Put the main buttons in one column, and the profile
                            table + "Logout" in a second column. Then use
                            justify-content-between to justify them correctly */}
                        <Row className="justify-content-between navbar-row">
                            <Col md="auto">
                                {/* use `justify` so all buttons are same width */}
                                <Nav justify id="ButtonContainer" className="mr-auto navButtonContainer"
                                    activeKey={this.props.currentPage}
                                    style={{
                                        // `justify` only works if there's
                                        // enough width to fit all buttons
                                        width: (numItems * BUTTON_WIDTH) + "px"
                                    }}
                                >
                                    <Nav.Link className="LinkButtons Navbar_Buttons" href="#/dashboard">Home</Nav.Link>
                                    <Nav.Link className="LinkButtons Navbar_Buttons" href="#/search">Auction<br />Search</Nav.Link>
                                    <Nav.Link className="LinkButtons Navbar_Buttons" href="#/history">Auction<br />History</Nav.Link>

                                    {adminLink}
                                </Nav>
                            </Col>
                            <Col md="auto">
                                <Nav className="mr-auto justify-content-end navButtonContainer">
                                    {this.props.profile.guest ? <>
                                        <Navbar.Text className="LinkButtons">Logged in as guest</Navbar.Text>
                                    </> : <>
                                        <Nav.Link className="LinkButtons Navbar_Buttons" href="#/profile">
                                            <table className="nav-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="tinyText">Deposit Balance</td>
                                                        <td className="tinyText">¥{commaNumbers(this.props.profile.currentDeposit)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tinyText">Account Balance</td>
                                                        {/* If we owe money, totalOwing will be positive. Negate it
                                                        so our balance is rendered as negative if we owe money. */}
                                                        <td className="tinyText">¥{commaNumbers(-this.props.profile.totalOwing)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tinyText">Translations</td>
                                                        <td className="tinyText">{this.props.profile.translation_requests_available === -1 ? "unlimited" : this.props.profile.translation_requests_available}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tinyText">Bids</td>
                                                        <td className="tinyText">{this.props.profile.bids_available === -1 ? "unlimited" : this.props.profile.bids_available}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tinyText">Japan Time</td>
                                                        <td className="tinyText"><TimeInJapan /></td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </Nav.Link>
                                    </>}
                                    <Nav.Link className="LinkButtons Navbar_Buttons" href="/logout">Logout</Nav.Link>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </Navbar>
                {!this.props.profile.guest && this.props.profile.nextDue !== null && <Alert variant='warning'>Payment of ¥{commaNumbers(this.props.profile.nextAmount)} due on {this.props.profile.nextDue}</Alert>}
                {this.props.message !== null && <Alert variant='info'>{this.props.message}</Alert>}

                <Modal show={this.props.popup !== null} onHide={this.props.clearPopup}>
                    <Modal.Body>{this.props.popup}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.clearPopup}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}

PCANav.defaultProps = {
    currentPage:null,
};

const mapStateToProps = state => {
    return {
        message: state.alert.message,
        popup: state.alert.popup,
        isAdmin: state.profile.is_admin,
        fetchingProfile: state.profile.fetchingProfile,
        loggedIn: state.profile.loggedIn,
        profile: state.profile
    };
};

const mapDispatchToProps = dispatch => ({
    setMessage: (newMessage) => dispatch(setMessage(newMessage)),
    clearMessage: () => dispatch(clearMessage()),
    logout: () => dispatch(logout()),
    failedLogin: () => dispatch(failedLogin()),
    clearPopup: () => dispatch(clearPopup())
});

// get the history with withrouter
// permits use of this.props.location.pathname
// https://stackoverflow.com/questions/42253277/react-router-v4-how-to-get-current-route
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(props => <PCANav {...props} />));
