const setAllPurchases = function (all) {
    return {
        type: "SET_ALL_PURCHASES",
        purchases: all
    };
};
const clearAllPurchases = function () {
    return {
        type: "CLEAR_ALL_PURCHASES"
    };
};

const showAddPayment = function (purchase_id, invoice_id, amount_outstanding) {
    return {
        type: "SHOW_ADD_PAYMENT",
        purchase_id,
        invoice_id,
        amount_outstanding,
    };
};

const hideAddPayment = function () {
    return {
        type: "HIDE_ADD_PAYMENT"
    };
};

const setAddPaymentCloseError = function(reason) {
    return {
        type: "ADD_PAYMENT_CLOSE_ERROR",
        reason,
    };
};

export {
    setAllPurchases,
    clearAllPurchases,
    showAddPayment,
    hideAddPayment,
    setAddPaymentCloseError,
};
