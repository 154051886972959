const all_purchases_defaultState = {
    loaded: false,
    purchases: [],
    lastUpdate: 0,
    showAddPayment: false,
    // purchase ID of the invoice we're adding a payment to
    addPaymentPurchaseID: null,
    // invoice ID that we're adding a payment to
    addPaymentInvoiceID: null,
    // outstanding amount on the invoice
    addPaymentAmountOutstanding: null,
    // if the user opts to close the invoice upon adding the payment, and
    // there's an error, it'll be stored here
    addPaymentCloseError: null,
};

const purchases = (state = all_purchases_defaultState, action) => {
    switch (action.type) {
    case "SET_ALL_PURCHASES":
        return {
            ...state,
            loaded: true,
            purchases: action.purchases,
            lastUpdate: (new Date()).getTime()
        };
    case "CLEAR_ALL_PURCHASES":
        return {
            ...all_purchases_defaultState
        };
    case "SHOW_ADD_PAYMENT":
        return {
            ...state,
            addPaymentPurchaseID: action.purchase_id,
            addPaymentInvoiceID: action.invoice_id,
            addPaymentAmountOutstanding: action.amount_outstanding,
            addPaymentCloseError: null,
            showAddPayment: true
        };
    case "HIDE_ADD_PAYMENT":
        return {
            ...state,
            showAddPayment: false,
            addPaymentPurchaseID: null,
            addPaymentInvoiceID: null,
            addPaymentAmountOutstanding: null,
            // (don't set or clear addPaymentCloseError here, we'll do that
            // after sending the request)
        };
    case "ADD_PAYMENT_CLOSE_ERROR":
        return {
            ...state,
            addPaymentCloseError: action.reason,
        };
    default:
        return state;
    }
};

export default purchases;
